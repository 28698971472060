import apiClient from "../apiClient";
import { apiBaseUrl } from "../../config";

export const getSelfServiceWorkflow = async (accountId) => {
  return apiClient
    .get(`${apiBaseUrl}/account-stages/admin/${accountId}/asf/workflow`)
    .then((response) => {
      return response;
    });
};

export const getAppointment = async (appointmentId) => {
  return apiClient
    .get(`${apiBaseUrl}/appointments/admin/${appointmentId}`)
    .then((response) => {
      return response;
    });
};

export const updateAppointmentStatus = async (appointmentId, data) => {
  return apiClient
    .put(`${apiBaseUrl}/appointments/admin/${appointmentId}/status`, data)
    .then((response) => {
      return response;
    });
};
