import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Menu, Transition } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/24/outline';

const statusList = [
    "active",
    "suspended",
    "completed",
    "pending",
    "canceled",
    "defaulter",
    "reactivated",
    "inactive", 
    "renewed"
]
const statusListContract = [
    "active"
]

const translateStatus = {
    "active": "Activo",
    "suspended": "Suspendido",
    "completed": "Completado",
    "pending": "Pendiente",
    "canceled": "Cancelado",
    "defaulter": "Moroso",
    "reactivated": "Reactivado",
    "inactive": "Inactivo",
    "renewed": "Renovado",
    "no_show": "No asistió"
}

const statusStyles = {
    "active": "bg-green-500 hover:bg-green-600 disabled:hover:bg-green-500",
    "suspended": "bg-purple-500 hover:bg-purple-600 disabled:hover:bg-purple-500",
    "completed": "bg-v2-blue-status/90 hover:bg-v2-blue-status disabled:bg-v2-blue-status/90",
    "renewed": "bg-v2-blue-status/90 hover:bg-v2-blue-status disabled:hover:bg-v2-blue-status/90",
    "pending": "bg-yellow-500 hover:bg-yellow-600 disabled:hover:bg-yellow-500",
    "canceled": "bg-red-500 hover:bg-red-600 disabled:hover:bg-red-500",
    "defaulter": "bg-orange-500 hover:bg-orange-600 disabled:hover:bg-orange-500",
    "reactivated": "bg-green-500 hover:bg-green-600 disabled:hover:bg-green-500",
    "inactive": "bg-yellow-500 hover:bg-yellow-600 disabled:hover:bg-yellow-500",
    "no_show": "bg-amber-500 hover:bg-amber-600 disabled:hover:bg-amber-500"
}

const itemsPositionList = ["top", "bottom"]

const itemsPositionValues = {
    "bottom": "origin-top-right",
    "top": "bottom-14"
}

function StatusMenuButton({ status, onChange, isContract, disabled, itemsPosition, customStatus, updateStatus}) {

    const [currentStatus, setCurrentStatus] = useState(status);
    const statusArray = customStatus || statusList

    useEffect(() => {
        if (statusList.includes(status)) setCurrentStatus(status);
    }, [status]);

    const handleStatusChange = (status) => {
        if (updateStatus) {
            updateStatus(status)
            return
        }
        setCurrentStatus(status)
        if (onChange) onChange(status);
    }

    return (
        <div className="z-20 flex">
            <Menu as="div" className="-ml-px relative block z-1 w-full">
                <Menu.Button disabled={disabled} className={`${ statusStyles[currentStatus]} disabled:cursor-not-allowed disabled:opacity-60 w-full text-white outline-none transition-all inline-flex gap-2 items-center justify-between px-4 py-2 shadow-sm text-base font-medium rounded-md`}>
                    <p className='text-nowrap'>{translateStatus[currentStatus]}</p>
                    {!disabled && <ChevronDownIcon className='h-5 w-5' />}
                </Menu.Button>
                <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95">
                    <Menu.Items className={`w-full ${itemsPositionValues[itemsPosition]} z-50 absolute right-0 mt-2 min-w-[250px] w-[250px] rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none`}>
                        <div className="py-1" >
                            {isContract ?  (statusListContract.map((status, i) => (
                                <Menu.Item key={i}>
                                    {({ active }) => (
                                        <button
                                            onClick={() => handleStatusChange(status)}
                                            className={`${active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'} block p-2 text-sm w-full text-left flex items-center gap-2`} >
                                            {translateStatus[status]}
                                            {currentStatus == status && (
                                                <CheckIcon className='w-4 h-4' />
                                            )}
                                        </button>
                                    )}
                                </Menu.Item>
                            ))) :  (statusArray.map((status, i) => (
                                <Menu.Item key={i}>
                                    {({ active }) => (
                                        <button
                                            onClick={() => handleStatusChange(status)}
                                            className={`${active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'} block p-2 text-sm w-full text-left flex items-center gap-2`} >
                                            {translateStatus[status]}
                                            {currentStatus == status && (
                                                <CheckIcon className='w-4 h-4' />
                                            )}
                                        </button>
                                    )}
                                </Menu.Item>
                            )))}
                        </div>
                    </Menu.Items>
                </Transition>
            </Menu>
        </div>
    )
}

StatusMenuButton.propTypes = {
    status: PropTypes.oneOf(statusList),
    onChange: PropTypes.func,
    isContract: PropTypes.bool,
    disabled: PropTypes.bool,
    itemsPosition: PropTypes.oneOf(itemsPositionList),
    customStatus: PropTypes.arrayOf(PropTypes.string),
    updateStatus: PropTypes.func
}

StatusMenuButton.defaultProps = {
    status: "pending",
    isContract: false,
    disabled: false,
    itemsPosition: "bottom",
    customStatus: null,
    updateStatus: null
}

export default StatusMenuButton;