import React, { useState } from "react";
import PropTypes from "prop-types";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faCopy } from "@fortawesome/free-solid-svg-icons";
import { Transition } from "@headlessui/react";

function ClipboardText({ text, textClassName }) {
  const [isCopied, setIsCopied] = useState(false);

  const copyTextToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(text);
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, 2000);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="relative flex items-center w-full">
      <div className="flex items-center max-w-[calc(100%-48px)] bg-neutral-50 rounded-5 rounded-r-none border h-12 px-4">
        <p className={`truncate ${textClassName}`}>{text}</p>
      </div>
      <button
        className="flex-1 flex items-center justify-center h-12 bg-button-orange hover:bg-button-orange/90 text-white rounded-5 rounded-l-none"
        onClick={copyTextToClipboard}
      >
        {isCopied ? (
          <FontAwesomeIcon icon={faCheck} />
        ) : (
          <FontAwesomeIcon icon={faCopy} />
        )}
      </button>
      <Transition
        show={isCopied}
        enter="transition ease-out duration-300"
        enterFrom="transform opacity-0 -translate-y-2"
        enterTo="transform opacity-100 translate-y-0"
        leave="transition ease-in duration-200"
        leaveFrom="transform opacity-100 translate-y-0"
        leaveTo="transform opacity-0 -translate-y-2"
        className="absolute top-0 right-0 -mr-2 -mt-8 px-2 py-1 bg-button-orange rounded-lg"
      >
        <div className="relative">
          <p className="text-xs font-medium text-white">Copiado</p>
          <div className="absolute left-1/2 transform -translate-x-1/2 bottom-[-8px] w-0 h-0 border-l-8 border-l-transparent border-r-8 border-r-transparent border-t-8 border-t-button-orange"></div>
        </div>
      </Transition>
    </div>
  );
}

ClipboardText.propTypes = {
  text: PropTypes.string,
  textClassName: PropTypes.string,
};

export default ClipboardText;
