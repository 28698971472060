import { useEffect, useState } from "react";
import { getSelfServiceWorkflow } from "../../apiClient/operations/selfServiceWorkflowOperations";

export function useWorkflow({ accountId }) {
  const [workflow, setData] = useState(null);

  const [isLoadingWorkflow, setIsLoadingWorkflow] = useState(false);
  const [errorWorkflow, setErrorWorkflow] = useState(false);

  const getTransformedData = (data) => {
    return {
      asfAccount: data.account,
      asfOrder: data.self_service_workflow[0],
      asfSatCredentials: data.self_service_workflow[1],
      asfAppointment: data.self_service_workflow[2],
    };
  };

  const getAppointmentFormatted = async () => {
    try {
      setIsLoadingWorkflow(true);
      const response = await getSelfServiceWorkflow(accountId);
      const transformedData = getTransformedData(response);
      setData(transformedData);
    } catch (error) {
      console.log("GET APPOINTMENT ERRORWORKFLOW: ", error);
      setErrorWorkflow(error.message);
    } finally {
      setIsLoadingWorkflow(false);
    }
  };

  useEffect(() => {
    if (accountId) getAppointmentFormatted();
  }, [accountId]);

  return { workflow, isLoadingWorkflow, errorWorkflow };
}
