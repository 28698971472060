import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";

import Badge from "../../tailwindUI/Badge";
import SkeletonLoader from "../../tailwindUI/SkeletonLoader";
import StatusMenuButton from "../../tailwindUI/StatusMenuButton";
import ClipboardText from "../../tailwindUI/ClipboardText";

import AlertError from "../../hooks/Alert";
import useFormatDate from "../../hooks/useFormatDate";
import { useOrder } from "../../hooks/asf/useOrder";
import { useWorkflow } from "../../hooks/asf/useWorkflow";
import { useAppointment } from "../../hooks/asf/useAppointment";

import { AlertContext } from "../../context/AlertContext";
import { UpdateStatusModal } from "./UpdateStatusModal";
import { getLead } from "../../apiClient/operations/leadsOperations";

function AsfInfo({ account }) {
  const { alert, setAlert } = useContext(AlertContext);
  const { formatDate } = useFormatDate();
  const [showAlert, setShowAlert] = useState(false);

  const { workflow, isLoadingWorkflow, workflowError } = useWorkflow({
    accountId: account?.id,
  });
  const orderId = workflow?.asfOrder?.source?.id;
  const appointmentId = workflow?.asfAppointment?.source?.id;

  const { order, isLoadingOrder } = useOrder({ orderId });
  const { appointment, updateAppointment, isLoadingAppointment } =
    useAppointment({ appointmentId });

  const connection = account.connections?.[0] || null;

  const [asfPaylink, setAsfPaylink] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [statusModal, setStatusModal] = useState("");

  const Empty = ({ text, placeholder = "placeholder" }) => {
    if (!text) {
      return (
        <dd className="mt-1 text-sm font-normal text-gray-500">
          {placeholder}
        </dd>
      );
    }
    return <dd className="mt-1 text-sm text-gray-900 font-normal">{text}</dd>;
  };

  useEffect(() => {
    if (account) {
      getLead(account.lead).then((data) => setAsfPaylink(data?.asf_paylink));
    }
  }, [account]);

  useEffect(() => {
    if (appointment) {
      setStatusModal(appointment.status);
    }
  }, [appointment]);

  useEffect(() => {
    if (workflowError) {
      setShowAlert(true);
      setAlert({ active: true, type: "error", message: workflowError });
    }
  }, [workflowError]);

  return (
    <>
      {showAlert && (
        <div className="xs:w-[102%] md:w-[94%] md:flex justify-center flex-col items-center">
          <div className="w-full flex justify-center">
            <AlertError type={alert.type} message={alert.message} />
          </div>
        </div>
      )}
      <div className="space-y-8">
        {asfPaylink ? (
          <section className="flex flex-col gap-y-8 pb-8 bg-white border border-gray-100 shadow-md rounded-lg">
            <header className="flex items-center h-[64px] px-6 border-b-2 border-gray-100 font-medium text-lg text-gray-900">
              {/* <span className="flex items-center justify-center pr-6 mr-6 border-r-2 border-gray-100 h-full">
              0
            </span> */}
              <h3 className="flex-1">Link ASF</h3>
            </header>
            <main className="px-6">
              <dl className="grid grid-cols-1">
                <dt className="text-sm font-medium text-gray-500">Link</dt>
                <dd className="mt-2">
                  <ClipboardText
                    textClassName="text-sm text-gray-900 font-normal"
                    text={asfPaylink}
                  />
                </dd>
              </dl>
            </main>
          </section>
        ) : (
          <section className="flex flex-col gap-y-8 pb-8 bg-white border border-gray-100 shadow-md rounded-lg">
            <header className="flex items-center h-[64px] px-6 border-b-2 border-gray-100 font-medium text-lg text-gray-900">
              <h3 className="flex-1">Link ASF</h3>
            </header>
            <main className="px-6">
              <dl className="grid grid-cols-1">
                <dt className="text-sm font-medium text-gray-500">Link</dt>
                <dd className="mt-2">
                  <SkeletonLoader width="w-full" />
                </dd>
              </dl>
            </main>
          </section>
        )}
        {isLoadingOrder || isLoadingWorkflow ? (
          <section className="flex flex-col gap-y-8 pb-8 bg-white border border-gray-100 shadow-md rounded-lg">
            <header className="flex items-center h-[64px] px-6 border-b-2 border-gray-100 font-medium text-lg text-gray-900">
              <span className="flex items-center justify-center pr-6 mr-6 border-r-2 border-gray-100 h-full">
                1
              </span>
              <h3 className="flex-1">Orden</h3>
              <SkeletonLoader width="w-24" />
            </header>
            <main className="px-6">
              <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">Nombre</dt>
                  <SkeletonLoader width="w-64" />
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">Monto</dt>
                  <SkeletonLoader width="w-64" />
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">
                    Fecha de pago
                  </dt>
                  <SkeletonLoader width="w-64" />
                </div>
              </dl>
            </main>
          </section>
        ) : (
          <section className="flex flex-col gap-y-8 pb-8 bg-white border border-gray-100 shadow-md rounded-lg">
            <header className="flex items-center h-[64px] px-6 border-b-2 border-gray-100 font-medium text-lg text-gray-900">
              <span className="flex items-center justify-center pr-6 mr-6 border-r-2 border-gray-100 h-full">
                1
              </span>
              <h3 className="flex-1">Orden</h3>
              <Badge text={workflow?.asfOrder?.status} />
            </header>
            <main className="px-6">
              <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">Nombre</dt>
                  <dd className="mt-1 text-sm text-gray-900 font-normal">
                    {workflow?.asfAccount?.name}
                  </dd>
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">Monto</dt>
                  <Empty text={order?.amount} placeholder="Monto pendiente" />
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">
                    Fecha de pago
                  </dt>
                  <Empty text={order?.date} placeholder="Fecha pendiente" />
                </div>
              </dl>
            </main>
          </section>
        )}

        {isLoadingWorkflow ? (
          <section className="flex flex-col gap-y-8 pb-8 bg-white border border-gray-100 shadow-md rounded-lg">
            <header className="flex items-center h-[64px] px-6 border-b-2 border-gray-100 font-medium text-lg text-gray-900">
              <span className="flex items-center justify-center pr-6 mr-6 border-r-2 border-gray-100 h-full">
                2
              </span>
              <h3 className="flex-1">Credenciales</h3>
              <SkeletonLoader width="w-24" />
            </header>
            <main className="px-6">
              <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">
                    Tipo de credenciales
                  </dt>
                  <SkeletonLoader width="w-64" />
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">RFC</dt>
                  <SkeletonLoader width="w-64" />
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">
                    Fecha de subida
                  </dt>
                  <SkeletonLoader width="w-64" />
                </div>
              </dl>
            </main>
          </section>
        ) : (
          <section className="flex flex-col gap-y-8 pb-8 bg-white border border-gray-100 shadow-md rounded-lg">
            <header className="flex items-center h-[64px] px-6 border-b-2 border-gray-100 font-medium text-lg text-gray-900">
              <span className="flex items-center justify-center pr-6 mr-6 border-r-2 border-gray-100 h-full">
                2
              </span>
              <h3 className="flex-1">Credenciales</h3>
              <Badge text={workflow?.asfSatCredentials?.status} />
            </header>
            <main className="px-6">
              <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">
                    Tipo de credenciales
                  </dt>
                  <Empty
                    text={connection?.credentials?.type?.toUpperCase()}
                    placeholder="Tipo Pendiente"
                  />
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">RFC</dt>
                  <Empty
                    text={connection?.credentials?.rfc}
                    placeholder="RFC pendiente"
                  />
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">
                    Fecha de subida
                  </dt>
                  <Empty
                    text={
                      connection?.created
                        ? formatDate(
                            new Date(connection?.created),
                            "D MMMM YYYY, h:mm a"
                          )
                        : null
                    }
                    placeholder="Fecha pendiente"
                  />
                </div>
              </dl>
            </main>
          </section>
        )}

        {isLoadingAppointment || isLoadingWorkflow ? (
          <section className="flex flex-col gap-y-8 pb-8 bg-white border border-gray-100 shadow-md rounded-lg">
            <header className="flex items-center h-[64px] px-6 border-b-2 border-gray-100 font-medium text-lg text-gray-900">
              <span className="flex items-center justify-center pr-6 mr-6 border-r-2 border-gray-100 h-full">
                3
              </span>
              <h3 className="flex-1">Cita ASF</h3>
              <SkeletonLoader width="w-24" />
            </header>
            <main className="px-6">
              <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">
                    Fecha de la sesión
                  </dt>
                  <SkeletonLoader width="w-64" />
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">
                    Hora de la sesión
                  </dt>
                  <SkeletonLoader width="w-64" />
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">
                    Ubicación
                  </dt>
                  <dd className="mt-1 space-y-2">
                    <SkeletonLoader width="w-64" />
                  </dd>
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">
                    Última actualización
                  </dt>
                  <dd className="mt-1 space-y-1 text-sm text-gray-900 font-normal">
                    <SkeletonLoader width="w-64" />
                  </dd>
                </div>
                <span className="h-[1px] col-span-full bg-gray-200" />
                <div className="col-span-full">
                  <h3 className="text-sm font-medium text-gray-900">
                    Preguntas de la cita
                  </h3>
                  <div className="mt-3 w-full space-y-4">
                    <SkeletonLoader width="w-72" />
                    <SkeletonLoader width="w-64" />
                  </div>
                </div>
              </dl>
            </main>
            <footer className="self-end px-6 space-x-4">
              <SkeletonLoader width="w-64" />
            </footer>
          </section>
        ) : (
          <section className="flex flex-col gap-y-8 pb-8 bg-white border border-gray-100 shadow-md rounded-lg">
            <header className="flex items-center h-[64px] px-6 border-b-2 border-gray-100 font-medium text-lg text-gray-900">
              <span className="flex items-center justify-center pr-6 mr-6 border-r-2 border-gray-100 h-full">
                3
              </span>
              <h3 className="flex-1">Cita ASF</h3>
              <Badge text={workflow?.asfAppointment?.status} />
            </header>
            <main className="px-6">
              <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">
                    Fecha de la sesión
                  </dt>
                  <Empty
                    text={appointment?.date}
                    placeholder="Fecha pendiente"
                  />
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">
                    Hora de la sesión
                  </dt>
                  <Empty
                    text={appointment?.time}
                    placeholder="Hora pendiente"
                  />
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">
                    Ubicación
                  </dt>
                  <dd className="mt-1 space-y-2">
                    <Empty
                      text={appointment?.type}
                      placeholder="Ubicación pendiente"
                    />
                    {appointment?.location && (
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={appointment.location}
                        className="text-sm text-orange-300 hover:underline hover:underline-offset-2 font-medium mt-1"
                      >
                        Unirse ahora
                      </a>
                    )}
                  </dd>
                </div>

                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">
                    Última actualización
                  </dt>
                  {appointment?.lastUpdate ? (
                    <dd className="mt-1 space-y-1 text-sm text-gray-900 font-normal">
                      <p>{appointment.lastUpdate.name}</p>
                      <p>{appointment.lastUpdate.date}</p>
                    </dd>
                  ) : (
                    <dd className="text-sm font-normal text-gray-500">
                      No hay actualizaciones
                    </dd>
                  )}
                </div>
                {appointment && (
                  <span className="h-[1px] col-span-full bg-gray-200" />
                )}
                {appointment?.questionsAnswers?.length > 0 && (
                  <div className="col-span-full">
                    <h3 className="text-sm font-medium text-gray-900">
                      Preguntas de la cita
                    </h3>
                    {appointment.questionsAnswers.map((question) => (
                      <div key={question.position} className="mt-3 w-full">
                        <dt className="text-sm font-medium text-gray-500">
                          P. {question.question}
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900 font-normal">
                          R. {question.answer}
                        </dd>
                      </div>
                    ))}
                  </div>
                )}
              </dl>
            </main>
            {appointment && (
              <footer className="self-end px-6 space-x-4">
                <StatusMenuButton
                  status={appointment.status}
                  disabled={appointment.status !== "active"}
                  updateStatus={(status) => {
                    if (status === "active") return;
                    setShowModal(true);
                    setStatusModal(status);
                  }}
                  customStatus={["active", "completed", "canceled", "no_show"]}
                  itemsPosition="top"
                />
              </footer>
            )}
          </section>
        )}
      </div>
      <UpdateStatusModal
        appointmentId={appointmentId}
        status={statusModal}
        showModal={showModal}
        setShowModal={setShowModal}
        setAlert={setAlert}
        setAppointment={() => updateAppointment()}
      />
    </>
  );
}

AsfInfo.propTypes = {
  account: PropTypes.object,
  text: PropTypes.string,
  placeholder: PropTypes.string,
};

export default AsfInfo;
