import { useEffect, useState } from "react";
import { getAppointment } from "../../apiClient/operations/selfServiceWorkflowOperations";
import useFormatDate from "../useFormatDate";

export function useAppointment({ appointmentId }) {
  const { formatDate } = useFormatDate();
  const [appointment, setAppointment] = useState(null);

  const [isLoadingAppointment, setIsLoadingAppointment] = useState(false);
  const [errorAppointment, setErrorAppointment] = useState(false);

  const getTransformedData = (data) => {
    const type = data.location.type === "google_conference"? "Google Meet": "Videollamada";
    const dateFormated = formatDate(new Date(data.start_date), "D MMMM [de] YYYY");
    const timeFormatted = `${formatDate(new Date(data.start_date),"h:mm a")} - ${formatDate(new Date(data.end_date), "h:mm a")}`;
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const lastRecordInHistory = data.history[data.history.length - 1];
    const lastUpdate = lastRecordInHistory ? {
          name: lastRecordInHistory.created_by.name,
          email: lastRecordInHistory.created_by.email,
          date: formatDate(new Date(lastRecordInHistory.created_at), "D MMMM YYYY, h:mm a"),
        }
      : null;


    return {
      date: dateFormated,
      time: `${timeFormatted} (${timezone})`,
      type,
      location: data.location.location,
      status: data.status,
      rescheduleUrl: data.actions.reschedule_url,
      questionsAnswers: data.questions_and_answers,
      lastUpdate,
    };
  };

  const getAppointmentFormatted = async () => {
    try {
      setIsLoadingAppointment(true);
      const response = await getAppointment(appointmentId);
      const transformedData = getTransformedData(response);
      setAppointment(transformedData);
    } catch (error) {
      console.log("GET APPOINTMENT ERRORAPPOINTMENT: ", error);
      setErrorAppointment(error.message);
    } finally {
      setIsLoadingAppointment(false);
    }
  };

  const updateAppointment = () => getAppointmentFormatted();

  useEffect(() => {
    if(appointmentId) {
      getAppointmentFormatted();
    }
  }, [appointmentId]);

  return {
    appointment,
    updateAppointment,
    isLoadingAppointment,
    errorAppointment,
  };
}
